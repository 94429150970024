@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;400;600;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

.text-gradient {
    background-clip: inherit;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@layer base {
    html {
        scroll-behavior: smooth;
        background-color: black;
    }
}

@layer utilities {
    ::-webkit-scrollbar {
        width: 7px;
        height: 5px;
    }
    ::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 8px;
    }
    ::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 8px;
    }
    .hide-scrollbar::-webkit-scrollbar {
        display: none;
    }
}
